import request from "@/api/service";

const api = {
   page: '/order/mallMarketing/marketingIncomeDetail',
   excel: '/order/mallMarketing/marketingIncomeDetailExcel',
   detail: '/order/mallMarketing/marketingIncomeDetailInfo'
}

export function getPage(query, params) {
   return request({
      url: api.page + query,
      method: 'POST',
      data: params
   })
}

export function getMarketingIncomeDetailExcel(params) {
   return request({
      url: api.excel,
      method: 'GET',
      params,
      responseType: 'blob'
   })
}

export function getMarketingIncomeDetailInfo(query, params) {
   return request({
      url: api.detail + query,
      method: 'POST',
      data: params
   })
}


