<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <label>选择酒店：</label>
            <el-select v-model="hotelId" placeholder="请选择">
              <el-option
                  v-for="item in hotelOpt"
                  :key="item.id"
                  :label="item.hotelName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-row>
          <el-row class="search-item">
            <label>营业时间：</label>
            <date-packer format="yyyy-MM-dd" :datePacker="dateRange" @setDatePacker="getDatePacker"></date-packer>
          </el-row>
          <el-button class="bg-gradient" type="primary" @click="handleQuery(true)">搜索</el-button>
          <el-button type="primary" plain @click="handleQuery(false)">重置</el-button>
          <el-button @click="handleExport" class="bg-gradient" type="primary">导出</el-button>
        </el-row>
      </el-row>
      <el-row class="table-box">
        <el-table v-loading="loading" :data="tableData" :stripe="true" border fit>
          <el-table-column label="序号">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column prop="date" label="日期"></el-table-column>
          <el-table-column prop="totalAmount" label="核销金额"></el-table-column>
          <el-table-column prop="orderNumber" label="核销订单数"></el-table-column>
          <el-table-column prop="productNum" label="核销商品数"></el-table-column>
          <el-table-column prop="userNumber" label="核销用户"></el-table-column>
          <el-table-column prop="writeOffAmount" label="推广金额"></el-table-column>
          <el-table-column prop="actualIncome" label="实际收入"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="handleDetail(scope.row.date)" type="text">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </div>
  </div>
</template>
<script>
import { getPage } from "@/api/shop/statement/incomeDetails";
import { selectHotel } from "@/api/shop/statement/salesOverview";
import { getMarketingIncomeDetailExcel } from "@/api/shop/statement/incomeDetails";
import {exportFile} from "@/common/js/common";
export default {
  data () {
    return {
      crumbs: new Map([
        ['特惠商城'], ['对账报表'], ['收入明细']
      ]),
      loading: false,
      hotelOpt: [],
      dateRange: [],
      tableData: [],
      total: 0,
      limit: 0,
      page: 1,
      hotelId: '',
    }
  },
  mounted() {
    this.limit = sessionStorage.getItem('pageSize') * 1;
    this.getHotelOpt()
  },
  methods: {
    // 获取酒店下拉
    getHotelOpt() {
      selectHotel({}).then(({ success, records }) => {
        if (success) {
          this.hotelOpt = records.map(item => ({ id: item.id, hotelName: item.hotelName }))
          if (this.hotelOpt.length > 0) {
            this.hotelId = this.hotelOpt[0].id
            this.getIncomeDetails()
          }
        }
      })
    },
    // 获取收入明细列表
    getIncomeDetails() {
      const query = `?limit=${ this.limit }&page=${ this.page }`,
            params = {
              hotelId: this.hotelId,
              beginDateStr: this.dateRange[0],
              endDateStr: this.dateRange[1]
            };
      getPage(query, params).then(({ success, total, records }) => {
        if (success) {
          this.loading = false
          this.total = total
          this.tableData = records
        }
      })
    },
    // 获取订单时间
    getDatePacker(val) {
      this.dateRange = val
    },
    // 查询订单
    handleQuery(bool){
      this.page = 1
      if (bool) return this.getIncomeDetails()
      this.dateRange = []
    },
    // 明细表格导出
    handleExport() {
      const params = {
        hotelId: this.hotelId,
        beginDateStr: this.dateRange[0], endDateStr: this.dateRange[1]
      };
      getMarketingIncomeDetailExcel(params).then(records => {
        exportFile(records, '收入明细报表')
      })
    },
    // 明细详情
    handleDetail(date) {
      this.$router.push({
        path: '/reconciliation_statement/income_details/detail',
        query: { hotelId: this.hotelId, dateStr: date }
      })
    }
  }
}
</script>
